import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  
  label {
    font-size: 1.6rem;
    margin-right: 10px;
  }

  select {
    border-radius: 4px;
    border: 2px solid #707070;
    height: 28px;
    width: 200px;
    background-color: #fff;

    transition: border-color 0.6s;
    transition: background-color 0.4s;
    &:focus {
      border-color: ${shade(0.2, '#00a857')};
      background-color: '#ff9999';
   }
}
`;
