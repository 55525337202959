import styled from 'styled-components';

export const Loading = styled.div`

`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px; 

  img {
    width: 180px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  h2 {
    color: #ff0000;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Card = styled.div`
  height: auto;
  width: 90%;
  border: 1px solid #000;
  margin-top: 15px;
`;

export const CardHead = styled.div`
  height: auto;
  width: 100%;
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 10px;

`;

export const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const Contact = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Option = styled.option`
  
`;

