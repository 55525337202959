import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoLogoWhatsapp } from 'react-icons/io';
import { mask, unMask } from 'remask';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import getValidationErrors from '../../validations/getValidationErrors';

import InputLabel from '../../components/InputLabel';
import CheckLabel from '../../components/CheckLabel';
import Button from '../../components/Button';
import Select from '../../components/InputSelect';

import api from '../../services/api';

import logo from '../../assets/logo.svg';

import {
  Contact,
  Container,
  Header,
  Card,
  CardHead,
  CardContent,
  Footer,
  Option
} from './styles';

const schema = Yup.object().shape({
  NomeCompleto: Yup.string().required('O nome é obrigatório'),
  Email: Yup.string()
    .email('Insira um e-mail valido')
    .required('O e-mail é obrigatorio'),
  Telefone: Yup.string().required('O telefone é obrigatório'),
  Atividade: Yup.number().required('O tipo de atividade é obrigatório'),
  Faturamento: Yup.string().required('O valor do faturamento é obrigatório'),
  Salarios: Yup.string().required('O valor dos salários são obrigatórios'),
  Compras: Yup.string().required('O valor de compras é obrigatório'),
  EnergiaEletrica: Yup.string().required(
    'O valor de energia elétrica é obrigatório'
  ),
  PercentualLucroFinal: Yup.string().required(
    'O percentual de lucro final é obrigatório'
  ),
});

export default function Dashboard() {
  const history = useHistory();
  
  const [NomeCompleto, setNomeCompleto] = useState('');
  const nomeRef = useRef(null);
  const [Email, setEmail] = useState('');
  const [Telefone, setTelefone] = useState('');
  const [Atividade, setAtividade] = useState(1);
  const [Faturamento, setFaturamento] = useState('');
  const [Salarios, setSalarios] = useState('');
  const [Compras, setCompras] = useState('');
  const [EnergiaEletrica, setEnergiaEletrica] = useState('');
  const [PercentualLucroFinal, setPercentualLucroFinal] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultado, setResultado] = useState(null);
  const [atividades, setAtividades] = useState(null);

  useEffect(() => {
    async function load() {
      try {
        const { data} = await api.get('/atividades');
        setAtividades(data); 
      } catch (error) {
        toast.error(`Não foi possível carregar as atividades da API principal: ${error}`);
      }
    }
    load();
  },[]);

  const handleNameChange = (value) => {
    setNomeCompleto(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleAtividade = (e) => {
    setAtividade(e.target.value);
  };

  const maskPhone = (value) => {
    setTelefone(mask(unMask(value), ['(99) 9999-9999', '(99) 9 9999-9999']));
  };

  const maskFaturamento = (value) => {
    setFaturamento(mask(unMask(value), ['9.99', '99.99', '999.99', '9,999.99', '99,999.99', '999,999.99', '9,999,999.99', '99,999,999.99', '999,999,999.99', '9,999,999,999.99']));
  }

  const maskSalarios = (value) => {
    setSalarios(mask(unMask(value), ['9.99', '99.99', '999.99', '9,999.99', '99,999.99', '999,999.99', '9,999,999.99', '99,999,999.99', '999,999,999.99', '9,999,999,999.99']));
  }

  const maskCompras = (value) => {
    setCompras(mask(unMask(value), ['9.99', '99.99', '999.99', '9,999.99', '99,999.99', '999,999.99', '9,999,999.99', '99,999,999.99', '999,999,999.99', '9,999,999,999.99']));
  }

  const maskEnergiaEletrica = (value) => {
    setEnergiaEletrica(mask(unMask(value), ['9.99', '99.99', '999.99', '9,999.99', '99,999.99', '999,999.99', '9,999,999.99', '99,999,999.99', '999,999,999.99', '9,999,999,999.99']));
  }

  const handlePercentual = (value) => {
    setPercentualLucroFinal(Number(value));
  };

  const handleSubmitInformations = async () => {
    setLoading(true);
    try {
      const dados = {
        NomeCompleto,
        Email,
        Telefone,
        Atividade: Number(Atividade),
        Faturamento: (Number(unMask(Faturamento)) / 100),
        Salarios: (Number(unMask(Salarios)) / 100),
        Compras: (Number(unMask(Compras)) / 100),
        EnergiaEletrica: (Number(unMask(EnergiaEletrica)) / 100),
        PercentualLucroFinal: Number(PercentualLucroFinal),
      };

      await schema.validate(dados, { abortEarly: false });
      const isValid = await schema.isValid(dados);

      if (isValid) {
        const response = await api.post('', dados);
        if (response.status === 200) {
          setResultado(response.data);
          setLoading(false);
          history.push({
            pathname: 'resultados',
            state: response.data
          });  
        } 
      }
      
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        
        toast.error(`Há erros de validação. Verifique e tente novamente: \n${Object.values(errors).toString()}\n`);
        
      } else if (error.response? error.response.status === 422 : false) {
        toast.error(`Erro ao processar sua solicitação: ${error.response.data.toString()}`);
        
      } else if (error.message? error.message === 'Network Error' : false) {
        toast.error('Houve um erro de rede durante sua solicitação. \nAltere suas configurações de segurança \npara essa página: \n Abra uma nova aba e cole o endereço copiado \n automaticamente pra você');
        navigator.clipboard.writeText('https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DDesktop&hl=pt-BR');
        
      } else {
        toast.error(`Erro ao reprocessar sua última solicitação: ${error.message}`);
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <img src={logo} alt="MG" />
      <Header>
        
        <h2>
          Simulador de Enquadramento Tributário, descubra qual o melhor para sua
          empresa
        </h2>
        <h3>
          Você sabia que através de um enquadramento tributário correto a sua
          empresa pode pagar o mesmo ou menos imposto com mais eficiência e
          atuando 100% na legalidade?
        </h3>
        <span>
          Preencha os campos abaixo com as informações da sua empresa. O
          simulador de Enquadramento Tributário da MG Contabilidade estimará,
        </span>
        <span>
          com base nas informações fornecidas, o Enquadramento Tributário mais
          indicado para sua empresa, sendo eles: Simples Nacional, Lucro
          Presumido ou Lucro Real.
        </span>
      </Header>
      <Card>
        <CardHead>
          <h3>Para saber o enquadramento correto para sua empresa, preencha</h3>
        </CardHead>
        <CardContent>
          <InputLabel
            name="NomeCompleto"
            label="Nome:"
            type="text"
            placeholder="Insira seu nome"
            value={NomeCompleto}
            onChange={(e) => handleNameChange(e.target.value)}
            ref={nomeRef}
            errored
          />

          <InputLabel
            label="Email:"
            type="text"
            placeholder="Insira seu email"
            width="100px"
            value={Email}
            onChange={(e) => handleEmailChange(e.target.value)}
          />

          {atividades ? (
            <Select
              label="Atividade:"
              value={Atividade}
              onChange={(e) => setAtividade(e.target.value)}
            >
              { atividades.map((atividade) => <Option key={atividade.id} value={atividade.id}>{atividade.descricao}</Option>)}
            </Select>
          ) : (
            <Select
              label="Atividade:"
              value={Atividade}
              onChange={(e) => handleAtividade(e.target.value)}
            >
              <Option value="invalid">Recarregue a página...</Option>
            </Select> 

          )}

          <InputLabel
            label="Telefone:"
            type="text"
            placeholder="(11) 9 9999-9999"
            value={Telefone}
            onChange={(e) => maskPhone(e.target.value)}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHead>
          <h3>Tipo de simulação</h3>
        </CardHead>
        <CardContent>
          <CheckLabel
            label="Enquadramento Tributário"
            type="checkbox"
            checked
          />
        </CardContent>
      </Card>
      <Card>
        <CardHead>
          <h3>Faturamento e Folha</h3>
        </CardHead>
        <CardContent>
          <InputLabel
            label="Faturamento Mensal:"
            type="text"
            placeholder="100000.00"
            value={Faturamento}
            onChange={(e) => maskFaturamento(e.target.value)}
          />
          <InputLabel
            label="Salários:"
            type="text"
            placeholder="90000.00"
            value={Salarios}
            onChange={(e) => maskSalarios(e.target.value)}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHead>
          <h3>Outras informações</h3>
        </CardHead>
        <CardContent>
          <InputLabel
            label="Compras:"
            type="text"
            placeholder="50000.00"
            value={Compras}
            onChange={(e) => maskCompras(e.target.value)}
          />
          <InputLabel
            label="Energia:"
            type="text"
            placeholder="100.00"
            value={EnergiaEletrica}
            onChange={(e) => maskEnergiaEletrica(e.target.value)}
          />
          <InputLabel
            label="Percentual lucro:"
            type="number"
            min="0.00"
            max="100.00"
            step="1"
            placeholder="5"
            value={PercentualLucroFinal}
            onChange={(e) => handlePercentual(e.target.value)}
          />
        </CardContent>
      </Card>
      <Footer>
        { loading ? (
          <Button type="button" onClick={() => handleSubmitInformations()}>
            <CircularProgress
              size={32}
              color="inherit"
            />
          </Button>
        ) : (
          <Button type="button" onClick={() => handleSubmitInformations()} disabled={!NomeCompleto || !Email || !Telefone || !Faturamento || !Salarios || !Compras || !EnergiaEletrica || !PercentualLucroFinal}>
            SIMULAR
          </Button>
        )}
        <Contact>
          <strong>MG Contécnica Contabilidade</strong>
          <a href="www.mgcontecnica.com.br">www.mgcontecnica.com.br</a>
        
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (11) 98782-2145
          </span>
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (21) 99595-5749
          </span>
        </Contact>
      </Footer>
    </Container>
  );
}
