import React, { useState, useRef, useCallback } from 'react';

import { Wrapper } from './styles';

const InputLabel = ({ label, errored, ...rest }) => {
  const inputRef = useRef(null);
  const [isFullfilled, setFullfilled] = useState(true);

  const handleFullfilled = useCallback(() => {
    setFullfilled(!!inputRef.current.value);
  }, []);

  return (
    <Wrapper>
      <span>{label}</span>
      <input 
        ref={inputRef}
        isFullfilled={isFullfilled}
        errored={errored}
        onBlur={handleFullfilled}
        {...rest}
      />
    </Wrapper>
  );
};

export default InputLabel;
