import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  span {
    font-size: 1.6rem;
  }
  input {
    margin: 10px;
    ${(props) =>
    props.errored &&
    css`
      border-color: #c53030;
    `}
    ${(props) =>
    props.isFullfilled &&
    css`
      border-color: #ff9000;
    `}
    border-radius: 4px;
    border: ${(props) => props.errored ? '2px solid #ff0000' : '2px solid #707070'};
    height: 28px;
    ${(props) => 
      props.width &&
      css`
        
      `
      }
    ::placeholder {
      font: 'Roboto';
    }
    transition: border-color 0.6s;
    transition: background-color 0.4s;

    &:focus {
      border-color: ${shade(0.2, '#00a857')};
      background-color: '#ff9999';
    }
  }
`;
