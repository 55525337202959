import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from '../pages/Main';
import Result from '../pages/Result';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/resultados" component={Result} />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
