import React from 'react';

import { Wrapper } from './styles';

const InputLabel = ({ label, ...rest }) => {
  return (
    <Wrapper>
      <input {...rest} />
      <span>{label}</span>
    </Wrapper>
  );
};

export default InputLabel;
