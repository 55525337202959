import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;

  img {
    width: 180px;
  }

  h1 {
    color: #ff0000;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  h2 {
    color: #ff0000;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Card = styled.div`
  height: auto;
  width: 90%;
  border: 1px solid #000;
  margin-top: 15px;
`;

export const CardHead = styled.div`
  height: 30px;
  width: 100%;
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px;

  input {
    margin: 10px;
  }

  div {
    margin-top: 10px;
    margin-right: 20px;
  }
`;

export const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  border: 2px solid #000;
  border-radius: 8px;
  padding: 15px;

  strong {
    margin-top: 5px;
  }

  background-color: ${props => props.isTotal ? '#ffc800' : 'transparent'};

  ${props => props.isValue && css`
      border: 2px solid #fff;
      background-color: rgba(255, 0, 0, 1);
      color: white;
  `}
`;

export const CardFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  button {
    margin-top: 15px;
    margin-bottom: 10px;

    border: none;
    border-radius: 8px;
    height: 44px;
    width: auto;
    padding: 10px;

    display: flex;
    align-items:center;
    justify-content:center;

    background-color: #ff0000;
    color: white;

    font-weight: bold;
    font-size: 1.8rem;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background: ${shade(0.2, '#ff0000')};
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;