import React from 'react';

import { Wrapper } from './styles';

const InputSelect = ({ name, label,...rest }) => {
  
  return (
    <Wrapper>
      <label htmlFor={name}>{label}</label>
      <select 
        name={name}
        {...rest}
      />
    </Wrapper>
  );
};

export default InputSelect;
