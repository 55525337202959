import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { IoLogoWhatsapp } from 'react-icons/io';

import logo from '../../assets/logo.svg';

import {
  Container, Header, Card, CardHead, CardContent, Indicator, CardFooter, Footer
} from './styles';

export default function Dashboard() {
  const history = useHistory();
  const location = useLocation();
  const dados = location.state;

  let recomendado;

  if (dados.lucroPresumido && dados.lucroPresumido.recomendado) {
    recomendado = dados.lucroPresumido;
    recomendado.enquadramento = 'Lucro Presumido';
  }
  if (dados.lucroReal && dados.lucroReal.recomendado) {
    recomendado = dados.lucroReal;
    recomendado.enquadramento = 'Lucro Real';
  }
  if (dados.simplesNacional && dados.simplesNacional.recomendado) {
    recomendado = dados.simplesNacional
    recomendado.enquadramento = 'Simples Nacional';
  }

  const handleSendWhatsAppMessage = () => {
    window.open('https://wa.me/5511987822145?text=Gostaria%20de%20mais%20informacoes%20sobre%20meu%20enquadramento%20tributario')
  }

  const handleTryAgain = () => {
    history.push('/');
  }
  
  return (
    recomendado ? 
    (
      <Container>
        <img src={logo} alt="MG" />
        <Header>
          <h2>Resultado da análise de enquadramento tributário</h2>
          <h3>Com base nas suas informações, o enquadramento tributário mais indicado para sua empresa é:</h3>
        </Header>
        <Indicator isValue>
          <h2>{recomendado.enquadramento}</h2>
        </Indicator>
        <Card>
          <CardHead>
            <h3>Valores de referência:</h3>
          </CardHead>
          <CardContent>
            <Indicator>
              <span>PIS</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.pis ? recomendado.pis : 0)}
              </strong>
            </Indicator>
            <Indicator>
              <span>Cofins</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.cofins ? recomendado.cofins : 0)}
              </strong>
            </Indicator>
            <Indicator>
              <span>IRPJ</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.irpj ? recomendado.irpj : 0)}
              </strong>
            </Indicator>
            <Indicator>
              <span>IRPJ Adicional</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.irpjAdicional? recomendado.irpjAdicional : 0)}
              </strong>
            </Indicator>
            <Indicator>
              <span>CSLL</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.csll? recomendado.csll : 0)}
              </strong>
            </Indicator>
            <Indicator>
              <span>INSS</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.inss ? recomendado.inss : 0)}
              </strong>
            </Indicator>
            {recomendado.icms && (
            <Indicator>
              <span>ICMS</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.icms || 0)}
              </strong>
            </Indicator>
)}
            <Indicator isTotal>
              <span>Total Imposto</span>
              <strong>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL'}).format(recomendado.totalImposto)}
              </strong>
            </Indicator>
          </CardContent>
          <CardFooter>
            <span>Os cálculos realizados são uma hipótese do melhor enquadramento tributário.</span>
            <span>
              {' '}
              Antes de tomar qualquer decisão:
              {' '}
              <strong>entre em contato com um de nossos especialistas</strong>
              , pois podem existir especifidades no seu ramo de atividade que interferem no cálculo da estimativa.
            </span>
            <button type="button" onClick={() => handleSendWhatsAppMessage()}>
              Fale AGORA com nossos especialistas
            </button>
          </CardFooter>
        </Card>
        <Footer>
          <strong>MG Contécnica Contabilidade</strong>
          <a href="www.mgcontecnica.com.br">www.mgcontecnica.com.br</a>
      
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (11) 98782-2145
          </span>
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (21) 99595-5749
          </span>
      
        </Footer>
      </Container>
      
) : 
    (
      <Container>
        <img src={logo} alt="MG" />
        <Header>
          <h2>Resultado da análise de enquadramento tributário</h2>
          <h3>Infelizmente não foi possível realizar o cálculo do seu enquadramento tributário.</h3>
        </Header>
        <CardFooter>
          <span>Isso pode ter acontecido em função de números digitados de forma errada ou algum erro ao processar os valores.</span>
          
          <button type="button" onClick={() => handleTryAgain()}>
            Tente realizar o cálculo novamente
          </button>
        </CardFooter>
        <Footer>
          <strong>MG Contécnica Contabilidade</strong>
          <a href="www.mgcontecnica.com.br">www.mgcontecnica.com.br</a>
      
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (11) 98782-2145
          </span>
          <span>
            <IoLogoWhatsapp size={24} color="#25D366" />
            {' '}
            (21) 99595-5749
          </span>
      
        </Footer>
      </Container>
)
  );
}
