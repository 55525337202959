import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  margin-top: 10px;
  background: ${props => props.disabled ? '#7a7a7a' : '#ff0000'};
  height: 46px;
  border-radius: 8px;
  border: 0;
  padding: 0 16px;
  color: #ffffff;
  width: 100%;
  font-size: 2.0rem;
  font-weight: bold;
  transition: background-color 0.2s;
  height: 43px;
  &:hover {
    background: ${props => props.disabled? '#7a7a7a' : shade(0.2, '#ff0000')};
  }
  cursor: ${props => props.disabled? 'not-allowed' : 'default'}
`;
