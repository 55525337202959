import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/globalStyle';

import Routes from './routes'; 

function App() {
  return (
    <Router>
      <Routes />
      <GlobalStyle />
      <ToastContainer autoClose={4000} />
    </Router>
  );
}

export default App;
